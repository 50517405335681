import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { PageBody } from "app/components/RouteComponents";
import { H1, P1 } from "app/components/Typography";
import Button from "app/components/Button";
import useUserSubscription from "hooks/Subscriptions/useUserSubscription";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Alert from "app/components/Alert";
import cancelFlowImage from "app/assets/images/steezy-cancel-flow.jpg";
import { useSubscriptionReactivateUsingCouponMutation } from "services/graphql";
import { AccountBodyWrapper } from "../AccountBodyWrapper";
import styles from "./styles.module.scss";

const COUPON_ID = "STARTDANCING50";

const CancelSuccess: React.FC = () => {
  const history = useHistory();
  const [showError, setShowError] = React.useState(false);
  const [data = {}, subscriptionLoading] = useUserSubscription();
  const [
    reactivateSubscriptionWithCoupon,
    { loading },
  ] = useSubscriptionReactivateUsingCouponMutation();

  if (subscriptionLoading || loading) {
    return <LoaderCentered />;
  }

  const {
    me: { primarySubscription },
  } = data;

  const handleAcceptOffer = async () => {
    try {
      setShowError(false);
      const response = await reactivateSubscriptionWithCoupon({
        variables: {
          input: {
            subscriptionId: primarySubscription.id,
            couponId: COUPON_ID,
          },
        },
      });

      if (response.data?.subscriptionReactivateUsingCoupon?.id) {
        history.push("/account/subscription");
      } else {
        setShowError(true);
      }
    } catch (err) {
      setShowError(true);
    }
  };

  const handleDecline = () => {
    history.push("/account/subscription");
  };

  return (
    <>
      <Helmet title="We hate to see you leave | STEEZY" />
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img
            src={cancelFlowImage}
            alt="STEEZY dancers"
            className={styles.imageGrid}
          />
        </div>
        <div className={styles.content}>
          <H1 className={styles.title}>We hate to see you leave</H1>
          <P1 className={styles.description}>
            We want dance to be accessible for everyone, so here is a limited
            time offer for{" "}
            <span className={styles.highlight}>
              50% off of your next {primarySubscription.plan.periodUnit}
            </span>{" "}
            of STEEZY Studio.
          </P1>
          <div className={styles.actions}>
            <Button
              variant="primary"
              width="100%"
              onClick={handleAcceptOffer}
              className={styles.acceptButton}
              disabled={loading}
            >
              Accept offer
            </Button>
            <Button
              variant="secondary"
              width="100%"
              onClick={handleDecline}
              className={styles.declineButton}
              disabled={loading}
            >
              No thanks
            </Button>
          </div>
          {showError && (
            <Alert variant="danger" className={styles.error}>
              There was an error applying your discount. Please try again or
              contact support.
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default (): JSX.Element => (
  <AccountBodyWrapper>
    <CancelSuccess />
  </AccountBodyWrapper>
);
